import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import Header from '../../../components/blog/header';
import SelectPopover from '../../../components/common/selectPopover';
import CTA from "../../../components/blog/cta";


const BestProductsPage = ({page, pageContext}) => {
  const baseUrl = '/tools/best-products/'
  const productFamilies = pageContext.product_families
    .map(p => Object.assign(p, {'count': p.products_products_aggregate.aggregate.count}))
    .sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));

  return (
    <Layout
      title="Reddit Product Reviews" ///subtitle={currentSize ? currentSize.title : ''}
      includeBanner={false} lightHeader={false}
      seoComponent={<SEO title={`Best products discussed on Reddit`} path={baseUrl} description="Explore the most popular products discussed and reviewed on Reddit" generateImage={true} />}
    >
      <div className="content-body markdown-body">
        <Header title="Reddit Product Reviews" subtitle={""} />
      </div>

      <div className=" text-gray-400">
        A growing collection of the most popular products recommended on Reddit with reviews.
      </div>
      <div className="mb-4 text-gray-400">
        Want a product category added? DM <a href="https://twitter.com/foliofed" target="_blank" className="underline">@foliofed</a> on Twitter.
      </div>

      <div className="">
        <ul>
          {productFamilies.map(p => (
            <li key={p.slug}>
              <Link to={`${baseUrl}${p.slug}/`} disabled={!p.products_products.length}
                className={`hover:underline cursor-pointer`}
              >
                <div>{p.name} <span className="font-bold opacity-25 ml-1 text-sm">{p.products_products_aggregate.aggregate.count}</span></div>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-20">
        <CTA />
      </div>


    </Layout>
  )
}


export default BestProductsPage;
