import React from "react";
import { Transition, Popover } from '@headlessui/react'

// shows a popover for a select dropdown (like choosing a group/sort)
const SelectPopover = ({
  options,
  currentOption,
  setCurrentOption,
  labelField,
  // custom styles?
  displayClassName,
  optionClassName,
}) => {

  const getLabel = (option) => {
    var label = undefined
    if (labelField && option){
      label = option[labelField]
    } else {
      label = option;
    }

    return label || 'missing label'
  }
  
  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`rounded-md text-gray-300 text-xs hover:text-gray-100 focus:outline-none flex items-center ${open ? 'text-white' : ''} ${displayClassName || ''}`}
          >
            {currentOption ? (
              <span>{getLabel(currentOption)}</span>
            ) : (
              <span>Select</span>
            )}
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </Popover.Button>

          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              static
              className="absolute z-10 top-full right-0 mt-2 px-1 w-screen max-w-xs sm:px-0 text-sm"
              style={{maxWidth: '200px'}}
            >
              <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-4 bg-white text-gray-900 p-4">
                  {options.map(o => (
                    <div key={getLabel(o)}
                      onClick={() => {
                        setCurrentOption(o);
                        close();
                      }}
                      className="cursor-pointer -m-2 p-2 flex items-center rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                    >
                      {/*<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>*/}

                      <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${getLabel(o) === getLabel(currentOption) ? 'text-green-400' : 'text-transparent'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>

                      <div className="ml-2">
                        <p className="font-medium text-gray-700">{getLabel(o)}</p>
                        {o.subtitle ? (
                          <p className="mt-0 font-xs text-gray-400">{o['subtitle']}</p>
                        ) : ''}
                      </div>

                    </div>
                  ))}
                  
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default SelectPopover;

